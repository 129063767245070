import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logoFooter.png';

const StarryBackground = () => {
  const stars = Array.from({ length: 100 }, (_, i) => ({
    id: i,
    size: Math.random() * 2 + 1,
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    animationDelay: `${Math.random() * 3}s`,
  }));

  return (
    <div className="absolute inset-0 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-radial from-gray-900 via-gray-800 to-gray-900" />
      {stars.map((star) => (
        <div
          key={star.id}
          className="absolute rounded-full bg-white animate-twinkle"
          style={{
            width: `${star.size}px`,
            height: `${star.size}px`,
            top: star.top,
            left: star.left,
            animationDelay: star.animationDelay,
            opacity: Math.random() * 0.7 + 0.3,
          }}
        />
      ))}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 via-purple-500/5 to-transparent animate-pulse" />
    </div>
  );
};

const NewsletterForm = ({
  email,
  onEmailChange,
  onSubmit,
  isSubscribed,
  onResubscribe,
  errorMessage,
}) => (
  <div className="p-8 sm:p-16 mb-12 sm:mb-24 pt-6 border-gray-700 border-b">
    <div className="flex flex-col md:flex-row md:items-center md:space-x-8">
      <div className="md:w-1/2 mb-6 md:mb-0">
        <h2 className="text-lg sm:text-xl font-semibold text-center md:text-left">
          Join our email subscription now to get updates on new opportunities
          and notifications.
        </h2>
      </div>

      <div className="md:w-1/2">
        {isSubscribed ? (
          <div className="text-center">
            <p className="text-white text-lg font-semibold mb-4">
              Thank you for subscribing to our email list!
            </p>
            <button
              className="bg-custome-gray text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition-colors"
              onClick={onResubscribe}
            >
              Subscribe Another Email
            </button>
          </div>
        ) : (
          <form
            className="flex flex-col sm:flex-row gap-2 sm:gap-0"
            onSubmit={onSubmit}
          >
            <input
              name="news-letter"
              className="flex-grow py-2 px-4 rounded-lg sm:rounded-r-none text-black"
              placeholder="Enter Your Email"
              type="email"
              value={email}
              onChange={onEmailChange}
              required
            />
            <button
              type="submit"
              className="bg-custome-gray border border-white w-auto text-white py-2 px-4 rounded-lg sm:rounded-l-none hover:bg-gray-500 transition-colors"
            >
              Subscribe Now
            </button>
          </form>
        )}
        {errorMessage && (
          <p className="mt-2 text-red-500 text-sm text-center sm:text-left">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  </div>
);

const CompanyInfo = () => (
  <div className="w-full md:ml-20 lg:ml-28 lg:w-1/3 mb-8 lg:mb-0">
    <img
      src={logo}
      alt="TalentFirst Logo"
      className="mb-6 w-32 mx-auto lg:mx-0"
    />
    <p className="text-sm leading-relaxed text-center lg:text-left mb-10">
      TalentFirst connects top talent with leading companies globally. We bridge
      the gap between opportunity and talent, empowering both job seekers and
      employers with innovative solutions.
    </p>

    <div className="text-center lg:text-left">
      <h2 className="text-xl font-semibold mb-2">
        Chainlink Technology Pvt Ltd
      </h2>
      <address className="text-sm not-italic">
        <div className="mb-2">
          29/A, 27th Main Rd, Sector 5,
          <br />
          11st Sector, HSR Layout,
          <br />
          Bengaluru, Karnataka 560102
        </div>
        <div className="mb-2">
          Phone:{' '}
          <a href="tel:+91-7975563057" className="hover:underline">
            +91-7975563057
          </a>
        </div>
        <div>
          Email:{' '}
          <a
            href="mailto:connect@talentfirst.global"
            className="hover:underline text-blue-400"
          >
            connect@talentfirst.global
          </a>
        </div>
      </address>

      <div className="mt-6 flex justify-center lg:justify-start space-x-4">
        {[
          {
            href: 'https://www.linkedin.com/company/talentfirst-global',
            label: 'LinkedIn',
          },
          {
            href: 'https://www.instagram.com/talentfirst_recruiting/',
            label: 'Instagram',
          },
          {
            href: 'https://www.facebook.com/talentfirstrecruiting',
            label: 'Facebook',
          },
        ].map(({ href, label }) => (
          <a
            key={label}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline text-white transition-colors"
          >
            {label}
          </a>
        ))}
      </div>
    </div>
  </div>
);

const NavigationLinks = () => (
  <div className="w-full lg:w-2/3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-center lg:text-left">
    <div>
      <h4 className="text-lg font-semibold mb-4">Site Links</h4>
      <ul className="space-y-3">
        {['Home', 'About', 'For Employers', 'For Talent'].map((label) => (
          <li key={label}>
            <Link
              to={`/${label.toLowerCase().replace(' ', '')}`}
              className="hover:underline transition-colors"
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>

    <div>
      <h4 className="text-lg font-semibold mb-4">Legal</h4>
      <ul className="space-y-3">
        {[
          { path: '/privacypolicy', label: 'Privacy Policy' },
          { path: '/refund_policy', label: 'Refund Policy' },
          { path: '/terms&conditions', label: 'Terms & Conditions' },
        ].map(({ path, label }) => (
          <li key={label}>
            <Link to={path} className="hover:underline transition-colors">
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>

    <div>
      <h4 className="text-lg font-semibold mb-4">Contact</h4>
      <ul className="space-y-3">
        <li>
          <Link to="/contact" className="hover:underline transition-colors">
            Contact Us
          </Link>
        </li>
      </ul>
    </div>
  </div>
);

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/api/emaillist`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setIsSubscribed(true);
        setEmail('');
        setErrorMessage('');
      } else {
        setErrorMessage('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      setErrorMessage('There was a network error. Please try again later.');
      console.error('Network error:', error);
    }
  };

  return (
    <footer className="relative bg-gray-900 text-white py-16">
      <StarryBackground />
      <div className="absolute inset-0 bg-gray-900/30" />

      <div className="relative z-10 container mx-auto px-4">
        <NewsletterForm
          email={email}
          onEmailChange={(e) => setEmail(e.target.value)}
          onSubmit={handleEmailSubmit}
          isSubscribed={isSubscribed}
          onResubscribe={() => setIsSubscribed(false)}
          errorMessage={errorMessage}
        />

        <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-52 xl:space-x-72 2xl:space-x-96">
          <CompanyInfo />
          <NavigationLinks />
        </div>

        <div className="mt-8 border-t border-gray-700 pt-6 text-center">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} TalentFirst. All rights reserved.
            Chainlink Technology Pvt. Ltd.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
