import React from 'react';
import { CheckCircle, Calendar, ArrowRight, Clock, Mail } from 'lucide-react';

const ThankYouPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50/20 flex items-center">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <div className="relative">
          {/* Decorative elements */}
          <div className="absolute left-0 top-0 w-24 h-24 bg-blue-100/50 rounded-full -translate-x-1/2 -translate-y-1/2" />
          <div className="absolute right-0 bottom-0 w-32 h-32 bg-blue-100/50 rounded-full translate-x-1/4 translate-y-1/4" />

          <div className="relative bg-white rounded-2xl shadow-xl overflow-hidden">
            <div className="p-8 sm:p-12">
              {/* Success Icon */}
              <div className="flex justify-center mb-8">
                <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center">
                  <CheckCircle className="w-12 h-12 text-green-600" />
                </div>
              </div>

              {/* Main Content */}
              <div className="text-center mb-12">
                <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
                  Thank You for Scheduling a Consultation
                </h1>
                <p className="text-xl text-gray-600">
                  We're looking forward to discussing your hiring needs and
                  finding the perfect solution for your team.
                </p>
              </div>

              {/* Next Steps */}
              <div className="grid sm:grid-cols-3 gap-6 mb-12">
                <div className="bg-gray-50 p-6 rounded-xl">
                  <Calendar className="w-8 h-8 text-blue-600 mb-4" />
                  <h3 className="font-semibold text-gray-900 mb-2">
                    Check Your Calendar
                  </h3>
                  <p className="text-sm text-gray-600">
                    You'll receive a calendar invite for our scheduled meeting
                  </p>
                </div>

                <div className="bg-gray-50 p-6 rounded-xl">
                  <Clock className="w-8 h-8 text-blue-600 mb-4" />
                  <h3 className="font-semibold text-gray-900 mb-2">
                    Prepare Questions
                  </h3>
                  <p className="text-sm text-gray-600">
                    Make note of any specific questions you'd like to discuss
                  </p>
                </div>

                <div className="bg-gray-50 p-6 rounded-xl">
                  <Mail className="w-8 h-8 text-blue-600 mb-4" />
                  <h3 className="font-semibold text-gray-900 mb-2">
                    Check Email
                  </h3>
                  <p className="text-sm text-gray-600">
                    We've sent you a confirmation email with meeting details
                  </p>
                </div>
              </div>

              {/* Return to Home */}
              <div className="text-center">
                <a
                  href="https://www.talentfirst.global"
                  className="inline-flex items-center gap-2 text-blue-600 font-medium hover:text-blue-700 transition-colors"
                >
                  <span>Return to Homepage</span>
                  <ArrowRight className="w-4 h-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
