import GlobalProcess from '../components-page-wise/talent-page/GlobalProcess';
import JourneyPaths from '../components-page-wise/talent-page/JourneyPaths';
import TalentHero from '../components-page-wise/talent-page/TalentHero';
import MarketInsights from '../components-page-wise/talent-page/marketSelection/MainSection';
import CallToAction from '../components-page-wise/talent-page/CallToAction';

const Talent = () => {
  return (
    <div className="bg-white">
      <TalentHero />
      <JourneyPaths />
      <GlobalProcess />
      <MarketInsights />
      <CallToAction />
    </div>
  );
};

export default Talent;
