import React from 'react';

const CustomButton = ({
  children,
  variant = 'primary',
  className = '',
  ...props
}) => {
  const baseStyles =
    'px-4 sm:px-6 py-2 sm:py-3 rounded-md font-medium transition-all duration-200 text-sm sm:text-base lg:text-lg';
  const variants = {
    primary: 'bg-blue-600 text-white hover:bg-blue-700 shadow-lg',
    outline: 'border-2 border-blue-600 text-blue-600 hover:bg-blue-50',
  };

  return (
    <button
      onClick={() =>
        (window.location.href = 'https://talent.talentfirst.global')
      }
      className={`${baseStyles} ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

const CallToAction = () => {
  return (
    <div className="relative overflow-hidden">
      {/* Animated background */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-indigo-50">
        {/* Animated circles */}
        <div className="absolute w-48 sm:w-72 h-48 sm:h-72 rounded-full bg-blue-200/40 blur-sm -top-20 -left-20 animate-float" />
        <div className="absolute w-64 sm:w-96 h-64 sm:h-96 rounded-full bg-indigo-200/40 blur-sm -bottom-32 -right-32 animate-float-delay" />

        {/* Animated gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-r from-blue-100/30 to-indigo-100/30 animate-pulse-slow" />

        {/* Enhanced moving particles - Responsive sizes */}
        <div className="absolute inset-0">
          {/* Large particles */}
          <div className="absolute w-6 sm:w-8 h-6 sm:h-8 rounded-full bg-blue-300/40 blur-sm top-1/4 left-1/4 animate-particle-1" />
          <div className="absolute w-4 sm:w-6 h-4 sm:h-6 rounded-full bg-indigo-300/40 blur-sm top-3/4 left-1/3 animate-particle-2" />
          <div className="absolute w-8 sm:w-10 h-8 sm:h-10 rounded-full bg-blue-300/40 blur-sm top-1/2 right-1/4 animate-particle-3" />

          {/* Medium particles */}
          <div className="absolute w-4 sm:w-5 h-4 sm:h-5 rounded-full bg-blue-400/30 blur-sm top-1/3 left-1/2 animate-particle-4" />
          <div className="absolute w-3 sm:w-4 h-3 sm:h-4 rounded-full bg-indigo-400/30 blur-sm top-2/3 right-1/3 animate-particle-5" />

          {/* Small particles */}
          <div className="absolute w-2 sm:w-3 h-2 sm:h-3 rounded-full bg-blue-500/30 top-1/2 left-2/3 animate-particle-6" />
          <div className="absolute w-1.5 sm:w-2 h-1.5 sm:h-2 rounded-full bg-indigo-500/30 top-1/4 right-1/2 animate-particle-7" />
        </div>
      </div>

      {/* Content */}
      <div
        className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 
                    py-16 sm:py-20 lg:py-24 text-center"
      >
        <h2
          className="text-2xl sm:text-3xl lg:text-4xl font-bold 
                     mb-4 sm:mb-6 text-gray-800 animate-fade-in"
        >
          Start Your Journey Today?
        </h2>
        <p
          className="text-base sm:text-lg lg:text-xl text-gray-600 
                    mb-8 sm:mb-10 lg:mb-12 max-w-xl sm:max-w-2xl mx-auto 
                    animate-fade-in-delay"
        >
          Join our platform to access courses, career opportunities, and expert
          mentorship.
        </p>
        <div className="animate-fade-in-delay-2">
          <CustomButton className="w-full sm:w-auto">
            Join TalentFirst Today
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

// Enhanced animations with mobile-first approach
const styles = `
@keyframes float {
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-10px) sm:translateY(-20px) rotate(5deg); }
}

@keyframes particle {
  0% { transform: translate(0, 0); }
  25% { transform: translate(50px, -50px); }
  50% { transform: translate(100px, 0); }
  75% { transform: translate(50px, 50px); }
  100% { transform: translate(0, 0); }
}

@media (min-width: 640px) {
  @keyframes particle {
    0% { transform: translate(0, 0); }
    25% { transform: translate(100px, -100px); }
    50% { transform: translate(200px, 0); }
    75% { transform: translate(100px, 100px); }
    100% { transform: translate(0, 0); }
  }
}

.animate-float {
  animation: float 8s ease-in-out infinite;
}

.animate-float-delay {
  animation: float 8s ease-in-out infinite;
  animation-delay: -4s;
}

.animate-pulse-slow {
  animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-particle-1 { animation: particle 15s linear infinite; }
.animate-particle-2 { animation: particle 20s linear infinite; animation-delay: -5s; }
.animate-particle-3 { animation: particle 25s linear infinite; animation-delay: -10s; }
.animate-particle-4 { animation: particle 18s linear infinite; animation-delay: -3s; }
.animate-particle-5 { animation: particle 22s linear infinite; animation-delay: -7s; }
.animate-particle-6 { animation: particle 16s linear infinite; animation-delay: -2s; }
.animate-particle-7 { animation: particle 24s linear infinite; animation-delay: -8s; }

.animate-fade-in {
  animation: fadeIn 0.8s ease-out;
}

.animate-fade-in-delay {
  animation: fadeIn 0.8s ease-out;
  animation-delay: 0.2s;
}

.animate-fade-in-delay-2 {
  animation: fadeIn 0.8s ease-out;
  animation-delay: 0.4s;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@media (min-width: 640px) {
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
}
`;

const StyleTag = () => <style>{styles}</style>;

const AnimatedCallToAction = () => (
  <>
    <StyleTag />
    <CallToAction />
  </>
);

export default AnimatedCallToAction;
