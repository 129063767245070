import React, { useState, useEffect } from 'react';

const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-xl shadow-xl ${className}`}>{children}</div>
);

const CardHeader = ({ children, className = '' }) => (
  <div className={`px-6 pt-6 ${className}`}>{children}</div>
);

const CardTitle = ({ children, className = '' }) => (
  <h2 className={`text-2xl font-semibold ${className}`}>{children}</h2>
);

const CardContent = ({ children, className = '' }) => (
  <div className={`px-6 py-4 ${className}`}>{children}</div>
);

const CardFooter = ({ children, className = '' }) => (
  <div className={`px-6 py-4 ${className}`}>{children}</div>
);

const Input = ({ className = '', ...props }) => (
  <input
    className={`w-full px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent ${className}`}
    {...props}
  />
);

const Button = ({
  variant = 'default',
  className = '',
  children,
  ...props
}) => {
  const baseStyles =
    'px-6 py-3 rounded-lg font-medium transition-colors duration-200';
  const variants = {
    default: 'bg-teal-500 text-white hover:bg-teal-600',
    outline: 'border-2 border-teal-500 text-teal-500 hover:bg-teal-50',
  };

  return (
    <button
      className={`${baseStyles} ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

const Radio = ({ id, checked, onChange, className = '', ...props }) => (
  <div className="relative flex items-center">
    <input
      type="radio"
      id={id}
      checked={checked}
      onChange={onChange}
      className={`w-5 h-5 border-2 border-gray-200 rounded-full appearance-none cursor-pointer checked:bg-teal-500 checked:border-10 ${className}`}
      {...props}
    />
  </div>
);

const Checkbox = ({ id, checked, onChange, className = '', ...props }) => (
  <input
    type="checkbox"
    id={id}
    checked={checked}
    onChange={onChange}
    className={`w-5 h-5 rounded border-2 border-gray-300 appearance-none cursor-pointer checked:bg-teal-500 checked:border-teal-500 ${className}`}
    {...props}
  />
);

// Custom hook for form persistence
const usePersistedState = (key, initialState) => {
  const [state, setState] = useState(() => {
    const savedState = sessionStorage.getItem(key);
    return savedState ? JSON.parse(savedState) : initialState;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

const SuccessMessage = ({ name, email }) => (
  <div className="text-center py-8 space-y-4">
    <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
      <svg
        className="w-8 h-8 text-green-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>
    <h2 className="text-2xl font-semibold text-gray-800">
      Thank you {name} for your submission!
    </h2>
    <p className="text-gray-600 max-w-md mx-auto">
      A team member will reach out shortly. We've sent a confirmation email to{' '}
      {email} with your responses.
    </p>
  </div>
);

const EmployerHiringForm = () => {
  const [step, setStep] = useState(1);
  const totalSteps = 7;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const buttonBaseClasses = 'text-base px-5 py-2.5 min-w-[100px]';

  // Use persisted state for form data
  const [formData, setFormData] = usePersistedState('employerFormData', {
    workEmail: '',
    hiringRange: '',
    employeeTypes: [],
    organizationType: '',
    officeSetup: '',
    fullName: '',
    additionalInfo: '',
  });

  const updateFormData = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setSubmitError(null);
  };

  const validateStep = (currentStep) => {
    let errorMessage = '';

    switch (currentStep) {
      case 1:
        if (!formData.workEmail.trim()) {
          errorMessage = 'Work email is required';
        } else if (!formData.workEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          errorMessage = 'Please enter a valid email address';
        }
        break;

      case 2:
        if (!formData.hiringRange) {
          errorMessage = 'Please select your hiring range';
        }
        break;

      case 3:
        if (formData.employeeTypes.length === 0) {
          errorMessage =
            'Please select at least one type of employee you want to hire';
        }
        break;

      case 4:
        if (!formData.officeSetup) {
          errorMessage = 'Please select your preferred office setup';
        }
        break;

      case 5:
        if (!formData.organizationType) {
          errorMessage = 'Please select your organization type';
        }
        break;

      case 6:
        if (!formData.fullName.trim()) {
          errorMessage = 'Full name is required';
        }
        break;
    }

    if (errorMessage) {
      setSubmitError(errorMessage);
      return false;
    }

    setSubmitError(null);
    return true;
  };

  const nextStep = () => {
    if (validateStep(step)) {
      setStep((prev) => prev + 1);
      setSubmitError(null);
    }
  };

  const prevStep = () => setStep((prev) => prev - 1);

  const clearFormData = () => {
    sessionStorage.removeItem('employerFormData');
    setFormData({
      workEmail: '',
      hiringRange: '',
      employeeTypes: [],
      organizationType: '',
      officeSetup: '',
      fullName: '',
      additionalInfo: '',
    });
  };

  const handleSubmit = async () => {
    if (!validateStep(step)) return;

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      const response = await fetch(process.env.REACT_APP_API_GATEWAY_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Submission failed');
      }

      setSubmitSuccess(true);
      clearFormData();
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitError(
        'There was an error submitting your form. Please try again or contact support: connect@talentfirst.global'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const progress = ((step - 1) / (totalSteps - 1)) * 100;

  const renderProgressBar = () => (
    <div className="space-y-2 mb-8">
      <div className="w-full bg-gray-100 h-1">
        <div
          className="h-full bg-teal-600 transition-all duration-300 ease-in-out"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <CardHeader className="space-y-1">
              <CardTitle>What's your work email?</CardTitle>
              <p className="text-gray-500">
                We'll use this to keep you updated on your hiring process
              </p>
            </CardHeader>
            <CardContent>
              <Input
                type="email"
                placeholder="your.email@company.com"
                value={formData.workEmail}
                onChange={(e) => updateFormData('workEmail', e.target.value)}
                className="text-base sm:text-lg py-3 sm:py-4"
              />
            </CardContent>
          </>
        );

      case 2:
        return (
          <>
            <CardHeader className="space-y-1">
              <CardTitle>
                How many people are you interested in hiring?
              </CardTitle>
              <p className="text-gray-500">
                This helps us understand the scale of your needs
              </p>
            </CardHeader>
            <CardContent>
              <div className="space-y-2 sm:space-y-3">
                {['1-10', '10-50', '50-100', '100-500', '500+'].map((range) => (
                  <label
                    key={range}
                    className="flex items-center space-x-2 sm:space-x-3 p-3 sm:p-4 rounded-lg border border-gray-200 hover:border-gray-300 transition-colors cursor-pointer"
                  >
                    <Radio
                      name="hiringRange"
                      id={range}
                      checked={formData.hiringRange === range}
                      onChange={() => updateFormData('hiringRange', range)}
                    />
                    <span className="text-lg">{range}</span>
                  </label>
                ))}
              </div>
            </CardContent>
          </>
        );

      case 3:
        return (
          <>
            <CardHeader className="space-y-1">
              <CardTitle>
                Which of the following are you looking to hire?
              </CardTitle>
              <p className="text-gray-500">
                Select all options that align with your hiring needs
              </p>
            </CardHeader>
            <CardContent>
              <div className="space-y-3">
                {[
                  { id: 'contract', label: 'Contract/Contingent Workers' },
                  { id: 'remote', label: 'Salaried Employees (Remote)' },
                  { id: 'inperson', label: 'Salaried Employees (In-Person)' },
                ].map(({ id, label }) => (
                  <label
                    key={id}
                    className="flex items-center space-x-3 p-4 rounded-lg border border-gray-200 hover:border-gray-300 transition-colors cursor-pointer"
                  >
                    <Checkbox
                      id={id}
                      checked={formData.employeeTypes.includes(id)}
                      onChange={(e) => {
                        const newTypes = e.target.checked
                          ? [...formData.employeeTypes, id]
                          : formData.employeeTypes.filter((t) => t !== id);
                        updateFormData('employeeTypes', newTypes);
                      }}
                    />
                    <span className="text-lg">{label}</span>
                  </label>
                ))}
              </div>
            </CardContent>
          </>
        );

      case 4:
        return (
          <>
            <CardHeader className="space-y-1">
              <CardTitle>
                Would you be interested in a managed office solution?
              </CardTitle>
              <p className="text-gray-500">
                Let us handle your workspace needs while you focus on growth
              </p>
            </CardHeader>
            <CardContent>
              <div className="space-y-3">
                {[
                  {
                    value: 'remote',
                    label: 'Fully Managed Remote Office',
                    desc: 'Complete virtual infrastructure and management',
                  },
                  {
                    value: 'inperson',
                    label: 'Fully Managed In-Person Office',
                    desc: 'Physical office space with full administrative support',
                  },
                  {
                    value: 'hybrid',
                    label: 'Hybrid Setup',
                    desc: 'Flexible combination of remote and in-person solutions',
                  },
                  {
                    value: 'not-interested',
                    label: 'Not Interested',
                    desc: 'Continue with existing office arrangements',
                  },
                ].map(({ value, label, desc }) => (
                  <label
                    key={value}
                    className="flex items-center space-x-3 p-4 rounded-lg border border-gray-200 hover:border-gray-300 transition-colors cursor-pointer"
                  >
                    <Radio
                      name="officeSetup"
                      id={value}
                      checked={formData.officeSetup === value}
                      onChange={() => updateFormData('officeSetup', value)}
                    />
                    <div>
                      <div className="text-lg">{label}</div>
                      <p className="text-sm text-gray-500 mt-1">{desc}</p>
                    </div>
                  </label>
                ))}
              </div>
            </CardContent>
          </>
        );

      case 5:
        return (
          <>
            <CardHeader className="space-y-1">
              <CardTitle>
                Which of the following best describes your organization?
              </CardTitle>
              <p className="text-gray-500">
                This helps us tailor our solutions to your industry
              </p>
            </CardHeader>
            <CardContent>
              <div className="space-y-3">
                {[
                  {
                    value: 'ai',
                    label: 'AI Company',
                    desc: 'Focused on artificial intelligence and machine learning',
                  },
                  {
                    value: 'tech',
                    label: 'Technology Company',
                    desc: 'Software, hardware, or digital services',
                  },
                  {
                    value: 'healthcare',
                    label: 'Healthcare',
                    desc: 'Medical services, healthcare technology, and patient care',
                  },
                  {
                    value: 'manufacturing',
                    label: 'Manufacturing',
                    desc: 'Industrial production and manufacturing operations',
                  },
                  {
                    value: 'financial',
                    label: 'Financial Services/Consultancy',
                    desc: 'Financial services, consulting, and business solutions',
                  },
                  {
                    value: 'mnc',
                    label: 'Multi-National Corporation',
                    desc: 'Global enterprise operations',
                  },
                ].map(({ value, label, desc }) => (
                  <label
                    key={value}
                    className="flex items-center space-x-3 p-4 rounded-lg border border-gray-200 hover:border-gray-300 transition-colors cursor-pointer"
                  >
                    <Radio
                      name="organizationType"
                      id={value}
                      checked={formData.organizationType === value}
                      onChange={() => updateFormData('organizationType', value)}
                    />
                    <div>
                      <div className="text-lg">{label}</div>
                      <p className="text-sm text-gray-500 mt-1">{desc}</p>
                    </div>
                  </label>
                ))}
              </div>
            </CardContent>
          </>
        );

      case 6:
        return (
          <>
            <CardHeader className="space-y-1">
              <CardTitle>What's your name?</CardTitle>
              <p className="text-gray-500">
                Help us personalize your experience
              </p>
            </CardHeader>
            <CardContent>
              <Input
                type="text"
                placeholder="Full Name"
                value={formData.fullName}
                onChange={(e) => updateFormData('fullName', e.target.value)}
                className="text-lg py-6"
              />
            </CardContent>
          </>
        );

      case 7:
        return (
          <>
            <CardHeader className="space-y-1">
              <CardTitle>Additional Information</CardTitle>
              <p className="text-gray-500">
                Share any specific requirements or questions you may have
              </p>
            </CardHeader>
            <CardContent>
              <Input
                type="text"
                placeholder="Type your message here..."
                value={formData.additionalInfo}
                onChange={(e) =>
                  updateFormData('additionalInfo', e.target.value)
                }
                className="text-lg py-6"
              />
            </CardContent>
          </>
        );

      default:
        return null;
    }
  };

  if (submitSuccess) {
    return (
      <Card className="w-full mt-10 border border-gray-50 max-w-2xl mx-auto sm:w-[95%]">
        <div className="p-4 sm:p-6 md:p-8">
          <SuccessMessage name={formData.fullName} email={formData.workEmail} />
        </div>
      </Card>
    );
  }

  return (
    <Card className="w-full mt-10 border border-gray-50 max-w-2xl mx-auto sm:w-[95%]">
      <div className="p-4 sm:p-6 md:p-8">
        {renderProgressBar()}
        {renderStep()}
        {submitError && (
          <div className="error-message px-6 mb-4 text-red-600 bg-red-50 py-2 rounded-lg flex items-center">
            <svg
              className="w-5 h-5 mr-2 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
            {submitError}
          </div>
        )}

        <CardFooter className="flex justify-between pt-8 mt-8 border-t border-gray-100">
          {step > 1 && (
            <Button
              variant="outline"
              onClick={prevStep}
              disabled={isSubmitting}
              className={buttonBaseClasses}
            >
              Previous
            </Button>
          )}
          {step < totalSteps ? (
            <Button
              onClick={nextStep}
              disabled={isSubmitting}
              className={`ml-auto ${buttonBaseClasses} next-button`}
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={handleSubmit}
              disabled={isSubmitting}
              className={`ml-auto ${buttonBaseClasses} relative`}
            >
              {isSubmitting ? (
                <>
                  <span className="opacity-0">Submit</span>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  </div>
                </>
              ) : (
                'Submit'
              )}
            </Button>
          )}
        </CardFooter>
      </div>
    </Card>
  );
};

export default EmployerHiringForm;
