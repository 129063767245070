// import { Globe, Rocket, Lightbulb, CheckCircle } from 'lucide-react';

// const GeometricBackground = () => (
//   <div className="absolute inset-0 overflow-hidden">
//     {/* Large circles */}
//     <div className="absolute -right-20 -top-20 h-96 w-96 rounded-full border-8 border-blue-200/30" />
//     <div className="absolute -left-40 bottom-0 h-72 w-72 rounded-full border-4 border-purple-200/30" />

//     {/* Small dots grid */}
//     <div className="absolute left-0 right-0 top-0">
//       <div className="grid grid-cols-12 gap-8 p-8">
//         {[...Array(48)].map((_, i) => (
//           <div key={i} className="h-2 w-2 rounded-full bg-gray-200/40" />
//         ))}
//       </div>
//     </div>

//     {/* Diagonal lines */}
//     <div className="absolute bottom-0 right-0 h-96 w-96">
//       <div className="relative h-full w-full rotate-12">
//         {[...Array(5)].map((_, i) => (
//           <div
//             key={i}
//             className="absolute h-px w-full bg-gradient-to-r from-transparent via-purple-200/40 to-transparent"
//             style={{ top: `${i * 20}%` }}
//           />
//         ))}
//       </div>
//     </div>

//     {/* Additional decorative elements */}
//     <div className="absolute left-1/4 top-1/4 h-32 w-32 rotate-45 border-2 border-amber-200/30" />
//     <div className="absolute bottom-1/4 right-1/3 h-24 w-24 rounded-lg border-2 border-blue-200/30" />
//   </div>
// );

// const JourneyPaths = () => {
//   const paths = [
//     {
//       icon: Globe,
//       title: 'International Placement',
//       description:
//         'Direct placement opportunities with leading companies in Europe, Middle East, and beyond',
//       metrics: ['30+ Countries', '48h Response', '95% Success Rate'],
//       gradient: 'from-blue-500 to-blue-600',
//     },
//     {
//       icon: Rocket,
//       title: 'Skill Acceleration',
//       description:
//         'Intensive training programs to prepare you for global tech roles and certifications',
//       metrics: ['12+ Programs', 'Industry Aligned', 'Mentor Support'],
//       gradient: 'from-purple-500 to-purple-600',
//     },
//     {
//       icon: Lightbulb,
//       title: 'Innovation Track',
//       description:
//         'Specialized paths for emerging technologies and future-ready skill development',
//       metrics: ['AI/ML Focus', 'Web3 Ready', 'Cloud Native'],
//       gradient: 'from-amber-500 to-amber-600',
//     },
//   ];

//   return (
//     <div className="relative flex min-h-[600px] md:min-h-[700px] lg:min-h-[800px] xl:min-h-[900px] items-center bg-gray-50/75 px-4 py-12 lg:py-16">
//       <GeometricBackground />

//       <div className="relative mx-auto w-full max-w-6xl">
//         <div className="mb-8 lg:mb-12 text-center">
//           <h2 className="mb-3 lg:mb-4 text-3xl lg:text-4xl font-bold tracking-tight text-gray-900">
//             Choose Your Growth Path
//           </h2>
//           <p className="mx-auto max-w-2xl text-base lg:text-lg text-gray-600">
//             Multiple pathways to accelerate your global career journey, tailored
//             to your goals and experience level
//           </p>
//         </div>

//         <div className="grid gap-6 lg:gap-8 md:grid-cols-3">
//           {paths.map((path, index) => {
//             const Icon = path.icon;
//             return (
//               <div
//                 key={index}
//                 className={`group relative overflow-hidden rounded-xl lg:rounded-2xl bg-white p-8 lg:p-10 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02]`}
//               >
//                 {/* Decorative corner accent */}
//                 <div
//                   className={`absolute -right-4 -top-4 h-16 w-16 bg-gradient-to-br ${path.gradient} opacity-10 blur-2xl transition-opacity duration-300 group-hover:opacity-20`}
//                 />

//                 <div className="relative">
//                   <div
//                     className={`mb-6 lg:mb-8 inline-flex h-12 lg:h-16 w-12 lg:w-16 items-center justify-center rounded-xl lg:rounded-2xl bg-gradient-to-br ${path.gradient} text-white`}
//                   >
//                     <Icon className="h-6 lg:h-8 w-6 lg:w-8" />
//                   </div>

//                   <h3 className="mb-3 lg:mb-4 text-xl lg:text-2xl font-semibold text-gray-900">
//                     {path.title}
//                   </h3>
//                   <p className="mb-8 lg:mb-10 text-base lg:text-lg text-gray-600">
//                     {path.description}
//                   </p>

//                   <div className="space-y-3 lg:space-y-4">
//                     {path.metrics.map((metric, idx) => (
//                       <div
//                         key={idx}
//                         className="flex items-center space-x-3 text-sm lg:text-base text-gray-700"
//                       >
//                         <CheckCircle className="h-5 lg:h-6 w-5 lg:w-6 text-green-500" />
//                         <span>{metric}</span>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JourneyPaths;

// import React from 'react';
// import { Globe, Rocket, Lightbulb, CheckCircle } from 'lucide-react';

// const EnhancedBackground = () => (
//   <div className="absolute inset-0 overflow-hidden">
//     {/* Animated gradient mesh */}
//     <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-purple-50 to-amber-50 opacity-60" />

//     {/* Geometric shapes with subtle animation */}
//     <div className="absolute inset-0">
//       <div
//         className="absolute -right-10 sm:-right-20 -top-10 sm:-top-20 h-72 sm:h-96 w-72 sm:w-96 rounded-full
//                     border-8 border-blue-200/20 backdrop-blur-sm animate-pulse"
//       />
//       <div
//         className="absolute -left-20 sm:-left-40 bottom-0 h-56 sm:h-72 w-56 sm:w-72 rounded-full
//                     border-4 border-purple-200/20 backdrop-blur-sm animate-pulse"
//         style={{ animationDelay: '1s' }}
//       />

//       {/* Floating dots */}
//       <div className="absolute inset-0 grid grid-cols-6 sm:grid-cols-12 gap-4 sm:gap-8 p-4 sm:p-8">
//         {[...Array(24)].map((_, i) => (
//           <div
//             key={i}
//             className="h-1 w-1 sm:h-2 sm:w-2 rounded-full bg-gray-400/20 animate-ping"
//             style={{ animationDuration: '3s', animationDelay: `${i * 0.1}s` }}
//           />
//         ))}
//       </div>

//       {/* Gradient lines */}
//       <div className="absolute bottom-0 right-0 h-72 sm:h-96 w-72 sm:w-96">
//         <div className="relative h-full w-full rotate-12">
//           {[...Array(5)].map((_, i) => (
//             <div
//               key={i}
//               className="absolute h-px w-full bg-gradient-to-r from-transparent via-purple-300/30 to-transparent
//                        animate-pulse"
//               style={{
//                 top: `${i * 20}%`,
//                 animationDelay: `${i * 0.2}s`,
//                 animationDuration: '3s',
//               }}
//             />
//           ))}
//         </div>
//       </div>
//     </div>
//   </div>
// );

// const JourneyPaths = () => {
//   const paths = [
//     {
//       icon: Globe,
//       title: 'International Placement',
//       description:
//         'Direct placement opportunities with leading companies in Europe, Middle East, and beyond',
//       metrics: ['30+ Countries', '48h Response', '95% Success Rate'],
//       gradient: 'from-blue-500 to-blue-600',
//     },
//     {
//       icon: Rocket,
//       title: 'Skill Acceleration',
//       description:
//         'Intensive training programs to prepare you for global tech roles and certifications',
//       metrics: ['12+ Programs', 'Industry Aligned', 'Mentor Support'],
//       gradient: 'from-purple-500 to-purple-600',
//     },
//     {
//       icon: Lightbulb,
//       title: 'Innovation Track',
//       description:
//         'Specialized paths for emerging technologies and future-ready skill development',
//       metrics: ['AI/ML Focus', 'Web3 Ready', 'Cloud Native'],
//       gradient: 'from-amber-500 to-amber-600',
//     },
//   ];

//   return (
//     <div
//       className="relative flex min-h-[500px] sm:min-h-[600px] md:min-h-[700px] lg:min-h-[800px]
//                     items-center bg-gray-50/75 px-4 py-8 sm:py-12 lg:py-16"
//     >
//       <EnhancedBackground />

//       <div className="relative mx-auto w-full max-w-6xl">
//         <div className="mb-6 sm:mb-8 lg:mb-12 text-center">
//           <h2 className="mb-2 sm:mb-3 lg:mb-4 text-2xl sm:text-3xl lg:text-4xl font-bold tracking-tight text-gray-900">
//             Choose Your Growth Path
//           </h2>
//           <p className="mx-auto max-w-2xl text-sm sm:text-base lg:text-lg text-gray-600">
//             Multiple pathways to accelerate your global career journey, tailored
//             to your goals and experience level
//           </p>
//         </div>

//         <div className="grid gap-4 sm:gap-6 lg:gap-8 md:grid-cols-3">
//           {paths.map((path, index) => {
//             const Icon = path.icon;
//             return (
//               <div
//                 key={index}
//                 className="group relative overflow-hidden rounded-lg sm:rounded-xl lg:rounded-2xl bg-white/90
//                          backdrop-blur-sm p-6 sm:p-8 lg:p-10 shadow-lg hover:shadow-xl transition-all duration-300
//                          hover:scale-[1.02]"
//               >
//                 {/* Enhanced gradient accent */}
//                 <div
//                   className={`absolute -right-4 -top-4 h-12 sm:h-16 w-12 sm:w-16 bg-gradient-to-br ${path.gradient}
//                            opacity-10 blur-2xl transition-all duration-300 group-hover:opacity-20
//                            group-hover:scale-110`}
//                 />

//                 <div className="relative">
//                   <div
//                     className={`mb-4 sm:mb-6 lg:mb-8 inline-flex h-10 sm:h-12 lg:h-16 w-10 sm:w-12 lg:w-16
//                              items-center justify-center rounded-lg sm:rounded-xl lg:rounded-2xl
//                              bg-gradient-to-br ${path.gradient} text-white`}
//                   >
//                     <Icon className="h-5 sm:h-6 lg:h-8 w-5 sm:w-6 lg:w-8" />
//                   </div>

//                   <h3 className="mb-2 sm:mb-3 lg:mb-4 text-lg sm:text-xl lg:text-2xl font-semibold text-gray-900">
//                     {path.title}
//                   </h3>
//                   <p className="mb-6 sm:mb-8 lg:mb-10 text-sm sm:text-base lg:text-lg text-gray-600">
//                     {path.description}
//                   </p>

//                   <div className="space-y-2 sm:space-y-3 lg:space-y-4">
//                     {path.metrics.map((metric, idx) => (
//                       <div
//                         key={idx}
//                         className="flex items-center space-x-2 sm:space-x-3 text-xs sm:text-sm lg:text-base
//                                  text-gray-700"
//                       >
//                         <CheckCircle className="h-4 sm:h-5 lg:h-6 w-4 sm:w-5 lg:w-6 text-green-500" />
//                         <span>{metric}</span>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JourneyPaths;
import React from 'react';
import { Globe, Rocket, Lightbulb, CheckCircle } from 'lucide-react';

const OpportunityCard = ({
  title,
  description,
  icon: Icon,
  metrics,
  gradient,
}) => (
  <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center sm:items-start">
    <div
      className={`h-10 w-10 rounded-lg ${gradient} flex items-center justify-center text-white mb-4`}
    >
      <Icon className="h-5 w-5" />
    </div>

    <h3 className="text-lg font-semibold text-gray-900 mb-2 text-center sm:text-left">
      {title}
    </h3>

    <p className="text-sm text-gray-600 mb-4 text-center sm:text-left">
      {description}
    </p>

    <div className="space-y-2 w-full">
      {metrics.map((metric, idx) => (
        <div
          key={idx}
          className="flex items-center gap-2 text-sm text-gray-700 justify-center sm:justify-start"
        >
          <CheckCircle className="h-4 w-4 text-green-500 flex-shrink-0" />
          <span>{metric}</span>
        </div>
      ))}
    </div>
  </div>
);

const TechPaths = () => {
  const opportunities = [
    {
      icon: Globe,
      title: 'Remote Tech Teams',
      description:
        'Join distributed engineering teams at leading tech companies while working from your preferred location',
      metrics: ['Flexible Hours', 'Global Teams', 'Latest Tech Stack'],
      gradient: 'bg-gradient-to-br from-blue-500 to-blue-600',
    },
    {
      icon: Rocket,
      title: 'Cutting-Edge Projects',
      description:
        'Work on innovative projects using AI, blockchain, cloud computing, and other emerging technologies',
      metrics: ['AI/ML Projects', 'Web3 Initiatives', 'Cloud Architecture'],
      gradient: 'bg-gradient-to-br from-purple-500 to-purple-600',
    },
    {
      icon: Lightbulb,
      title: 'Growth & Impact',
      description:
        'Make meaningful contributions to products used by millions while advancing your career in tech',
      metrics: ['Career Mentorship', 'Knowledge Sharing', 'Industry Networks'],
      gradient: 'bg-gradient-to-br from-amber-500 to-amber-600',
    },
  ];

  return (
    <div className="bg-gradient-to-br from-blue-50 via-white-50 to-white-50 py-12 px-4">
      <div className="max-w-5xl mx-auto">
        <div className="text-center mb-12">
          {/* Badge */}
          <div className="inline-flex items-center rounded-full bg-blue-50 px-3 py-1 mb-6 shadow-sm">
            <div className="h-2 w-2 rounded-full bg-blue-600 mr-2" />
            <span className="text-sm font-medium text-blue-900">
              Global Tech Opportunities
            </span>
          </div>

          {/* Title with gradint */}
          <h2 className="text-4xl sm:text-5xl font-semibold tracking-tight mb-4">
            Join Global Tech
            <span className="block mt-1 text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-red-500">
              Leaders
            </span>
          </h2>

          {/* Description */}
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Join cutting-edge tech companies in Europe and America, working
            remotely on projects that shape the future
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {opportunities.map((opportunity, index) => (
            <OpportunityCard key={index} {...opportunity} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechPaths;
