import React, { useState } from 'react';
import SelectionView from './SelectionView';
import ResultsView from './ResultsView';
import marketData from '../../../data/marketData';
import useIsMobile from '../../../hooks/useIsMobile';

const Header = ({ title, description }) => (
  <div
    className="py-4 sm:py-6 md:py-8 flex flex-col justify-center items-center md:items-center 
                  border-b border-gray-200"
  >
    <h2
      className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 
                   mb-2 sm:mb-3 tracking-tight text-center md:text-left"
    >
      {title}
    </h2>
    <p
      className="text-sm sm:text-base md:text-lg text-gray-600 
                  text-center max-w-2xl"
    >
      {description}
    </p>
  </div>
);

const MarketInsights = () => {
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedSubIndustry, setSelectedSubIndustry] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const isMobile = useIsMobile();

  const handleSelectionChange = (type, value) => {
    const selections = {
      industry: () => {
        setSelectedIndustry(selectedIndustry === value ? '' : value);
        setSelectedSubIndustry('');
        setSelectedRole('');
      },
      subIndustry: () => {
        setSelectedSubIndustry(selectedSubIndustry === value ? '' : value);
        setSelectedRole('');
      },
      role: () => {
        setSelectedRole(selectedRole === value ? '' : value);
      },
    };

    selections[type]?.();
  };

  const getAvailableCountries = () => {
    if (!selectedIndustry || !selectedSubIndustry || !selectedRole) return [];

    const roleData =
      marketData[selectedIndustry]?.subIndustries[selectedSubIndustry]?.roles[
        selectedRole
      ];
    return Object.entries(roleData || {});
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col p-1 sm:p-2">
        <Header
          title="Global Industry Opportunities"
          description="Explore opportunities across different industries in Europe by Selecting Industry, Sector and Role"
        />

        <div className="flex-1 flex flex-col md:flex-row gap-6 py-6">
          <SelectionView
            marketData={marketData}
            selectedIndustry={selectedIndustry}
            selectedSubIndustry={selectedSubIndustry}
            selectedRole={selectedRole}
            onSelectionChange={handleSelectionChange}
          />
          {/* {(!isMobile || (isMobile && selectedRole)) && (
            <ResultsView countries={getAvailableCountries()} />
          )} */}
          {/* {!isMobile ? (
            <ResultsView countries={getAvailableCountries()} />
          ) : isMobile && selectedRole ? (
            <ResultsView countries={getAvailableCountries()} />
          ) : (
            ' '
          )} */}
          {isMobile ? (
            selectedRole ? (
              <ResultsView countries={getAvailableCountries()} />
            ) : null
          ) : (
            <ResultsView countries={getAvailableCountries()} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketInsights;
