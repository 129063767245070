// marketData.js
const techMarketData = {
  'Frontend Development': {
    iconName: 'Code',
    subIndustries: {
      'Web Development': {
        roles: {
          'React Developer': {
            Germany: {
              demandLevel: 'Very High',
              demandGrowth: '+45% YoY',
              visaProcess: 'EU Blue Card eligible, fast processing',
              keyRequirements: [
                'Modern React patterns',
                'State management (Redux/Context)',
                'Next.js',
                'Performance optimization',
              ],
              techStack: ['React', 'TypeScript', 'Tailwind', 'Next.js'],
              cities: ['Berlin', 'Munich', 'Hamburg'],
              demandDrivers: [
                'Digital transformation initiatives',
                'E-commerce growth',
                'Start-up ecosystem expansion',
              ],
            },
            Netherlands: {
              demandLevel: 'High',
              demandGrowth: '+35% YoY',
              visaProcess: 'Highly skilled migrant visa',
              keyRequirements: [
                'Modern frontend architecture',
                'Testing practices',
                'CI/CD experience',
              ],
              techStack: ['React', 'JavaScript', 'CSS-in-JS'],
              cities: ['Amsterdam', 'Eindhoven', 'Rotterdam'],
              demandDrivers: [
                'Fintech sector growth',
                'Remote work hub development',
              ],
            },
          },
          'Vue.js Developer': {
            Germany: {
              demandLevel: 'High',
              demandGrowth: '+30% YoY',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'Vue.js ecosystem',
                'Modern build tools',
                'Frontend optimization',
              ],
              techStack: ['Vue.js', 'Vuex', 'Nuxt.js'],
              cities: ['Berlin', 'Frankfurt', 'Munich'],
              demandDrivers: [
                'Enterprise adoption of Vue.js',
                'E-commerce platforms growth',
              ],
            },
          },
        },
      },
    },
  },

  'Backend Development': {
    iconName: 'Server',
    subIndustries: {
      'API Development': {
        roles: {
          'Node.js Developer': {
            Germany: {
              demandLevel: 'Very High',
              demandGrowth: '+50% YoY',
              visaProcess: 'EU Blue Card preferred',
              keyRequirements: [
                'Microservices architecture',
                'RESTful APIs',
                'Database optimization',
                'Security best practices',
              ],
              techStack: ['Node.js', 'Express', 'MongoDB', 'PostgreSQL'],
              cities: ['Berlin', 'Munich', 'Hamburg'],
              demandDrivers: [
                'Microservices adoption',
                'API-first companies',
                'Cloud migration projects',
              ],
            },
          },
          'Python Backend Developer': {
            Netherlands: {
              demandLevel: 'Very High',
              demandGrowth: '+40% YoY',
              visaProcess: 'Knowledge migrant visa',
              keyRequirements: [
                'Python web frameworks',
                'API development',
                'Database design',
              ],
              techStack: ['Python', 'Django', 'FastAPI', 'PostgreSQL'],
              cities: ['Amsterdam', 'Rotterdam', 'Utrecht'],
              demandDrivers: [
                'AI/ML integration needs',
                'Fintech sector growth',
                'Scale-up expansion',
              ],
            },
          },
        },
      },
    },
  },

  'Cloud & DevOps': {
    iconName: 'Cloud',
    subIndustries: {
      'Cloud Infrastructure': {
        roles: {
          'DevOps Engineer': {
            Germany: {
              demandLevel: 'Critical',
              demandGrowth: '+55% YoY',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'Infrastructure as Code',
                'Container orchestration',
                'CI/CD pipelines',
                'Cloud security',
              ],
              techStack: ['Kubernetes', 'Docker', 'Terraform', 'AWS/Azure'],
              cities: ['Berlin', 'Munich', 'Frankfurt'],
              demandDrivers: [
                'Cloud migration initiatives',
                'Digital transformation',
                'Remote infrastructure needs',
              ],
            },
          },
          'Site Reliability Engineer': {
            Netherlands: {
              demandLevel: 'Very High',
              demandGrowth: '+45% YoY',
              visaProcess: 'Knowledge migrant visa',
              keyRequirements: [
                'System reliability',
                'Performance optimization',
                'Monitoring and alerting',
              ],
              techStack: ['Kubernetes', 'Prometheus', 'Grafana', 'Python'],
              cities: ['Amsterdam', 'Rotterdam', 'Utrecht'],
              demandDrivers: [
                'Scale-up infrastructure needs',
                'High availability requirements',
                'Cloud-native adoption',
              ],
            },
          },
        },
      },
    },
  },

  'Data Engineering': {
    iconName: 'Database',
    subIndustries: {
      'Big Data': {
        roles: {
          'Data Engineer': {
            Germany: {
              demandLevel: 'Very High',
              demandGrowth: '+40% YoY',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'Data pipeline development',
                'ETL processes',
                'Big data technologies',
              ],
              techStack: ['Python', 'Spark', 'Kafka', 'Airflow'],
              cities: ['Berlin', 'Munich', 'Frankfurt'],
              demandDrivers: [
                'Data-driven decision making',
                'AI/ML initiatives',
                'Big data processing needs',
              ],
            },
          },
        },
      },
    },
  },
};

export default techMarketData;
// const marketData = {
//   'Healthcare & Medical': {
//     iconName: 'Stethoscope',
//     subIndustries: {
//       'Medical Devices': {
//         roles: {
//           'R&D Engineer': {
//             Germany: {
//               demandLevel: 'Very High',
//               averageSalary: '€55,000 - €75,000',
//               visaProcess: 'EU Blue Card eligible, fast processing',
//               keyRequirements: [
//                 'Medical device design experience',
//                 'ISO 13485 knowledge',
//                 'Regulatory compliance experience',
//                 'Product development lifecycle',
//               ],
//               cities: ['Munich', 'Hamburg', 'Stuttgart'],
//             },
//             Netherlands: {
//               demandLevel: 'High',
//               averageSalary: '€50,000 - €70,000',
//               visaProcess: 'Highly skilled migrant visa',
//               keyRequirements: [
//                 'Medical technology background',
//                 'CE marking knowledge',
//                 'Quality systems expertise',
//               ],
//               cities: ['Amsterdam', 'Eindhoven', 'Rotterdam'],
//             },
//           },
//           'Quality Assurance Manager': {
//             Germany: {
//               demandLevel: 'High',
//               averageSalary: '€60,000 - €80,000',
//               visaProcess: 'EU Blue Card eligible',
//               keyRequirements: [
//                 'QMS implementation',
//                 'ISO 13485 certification',
//                 'Risk management',
//                 'Audit experience',
//               ],
//               cities: ['Berlin', 'Frankfurt', 'Munich'],
//             },
//           },
//         },
//       },
//       Pharmaceuticals: {
//         roles: {
//           'Process Engineer': {
//             Germany: {
//               demandLevel: 'Very High',
//               averageSalary: '€60,000 - €85,000',
//               visaProcess: 'EU Blue Card preferred',
//               keyRequirements: [
//                 'GMP knowledge',
//                 'Process optimization',
//                 'Validation experience',
//               ],
//               cities: ['Frankfurt', 'Berlin', 'Heidelberg'],
//             },
//           },
//         },
//       },
//     },
//   },

//   'Technology & IT': {
//     iconName: 'Laptop',
//     subIndustries: {
//       'Software Development': {
//         roles: {
//           'Full Stack Developer': {
//             Germany: {
//               demandLevel: 'Very High',
//               averageSalary: '€65,000 - €85,000',
//               visaProcess: 'EU Blue Card eligible',
//               keyRequirements: [
//                 'Modern JavaScript frameworks',
//                 'Cloud platforms',
//                 'System architecture',
//                 'API development',
//               ],
//               cities: ['Berlin', 'Munich', 'Hamburg'],
//             },
//           },
//           'DevOps Engineer': {
//             Netherlands: {
//               demandLevel: 'Very High',
//               averageSalary: '€60,000 - €80,000',
//               visaProcess: 'Knowledge migrant visa',
//               keyRequirements: [
//                 'CI/CD pipelines',
//                 'Cloud services (AWS/Azure)',
//                 'Kubernetes',
//                 'Infrastructure as Code',
//               ],
//               cities: ['Amsterdam', 'Rotterdam', 'Utrecht'],
//             },
//           },
//         },
//       },
//       'Data Science': {
//         roles: {
//           'Data Scientist': {
//             Germany: {
//               demandLevel: 'High',
//               averageSalary: '€60,000 - €80,000',
//               visaProcess: 'EU Blue Card eligible',
//               keyRequirements: [
//                 'Machine Learning',
//                 'Python/R',
//                 'Statistical Analysis',
//                 'Big Data Technologies',
//               ],
//               cities: ['Berlin', 'Munich', 'Frankfurt'],
//             },
//           },
//         },
//       },
//     },
//   },

//   'Manufacturing & Industry': {
//     iconName: 'Factory',
//     subIndustries: {
//       Automotive: {
//         roles: {
//           'Production Engineer': {
//             Germany: {
//               demandLevel: 'Very High',
//               averageSalary: '€52,000 - €72,000',
//               visaProcess: 'EU Blue Card eligible',
//               keyRequirements: [
//                 'Lean manufacturing',
//                 'Industry 4.0',
//                 'Quality control methods',
//                 'Process optimization',
//               ],
//               cities: ['Stuttgart', 'Munich', 'Wolfsburg'],
//             },
//           },
//           'Quality Manager': {
//             Germany: {
//               demandLevel: 'High',
//               averageSalary: '€55,000 - €75,000',
//               visaProcess: 'EU Blue Card eligible',
//               keyRequirements: [
//                 'ISO/TS 16949',
//                 'Quality management systems',
//                 'Audit experience',
//               ],
//               cities: ['Stuttgart', 'Munich', 'Wolfsburg'],
//             },
//           },
//         },
//       },
//       'Industrial Automation': {
//         roles: {
//           'Automation Engineer': {
//             Germany: {
//               demandLevel: 'Very High',
//               averageSalary: '€55,000 - €75,000',
//               visaProcess: 'EU Blue Card eligible',
//               keyRequirements: [
//                 'PLC Programming',
//                 'SCADA Systems',
//                 'Industrial IoT',
//                 'Robot Programming',
//               ],
//               cities: ['Stuttgart', 'Hamburg', 'Munich'],
//             },
//           },
//         },
//       },
//     },
//   },

//   'Energy & Utilities': {
//     iconName: 'Zap',
//     subIndustries: {
//       'Renewable Energy': {
//         roles: {
//           'Wind Energy Engineer': {
//             Germany: {
//               demandLevel: 'Very High',
//               averageSalary: '€55,000 - €75,000',
//               visaProcess: 'EU Blue Card eligible',
//               keyRequirements: [
//                 'Wind turbine technology',
//                 'Power systems',
//                 'Project management',
//                 'Technical documentation',
//               ],
//               cities: ['Hamburg', 'Bremen', 'Berlin'],
//             },
//           },
//         },
//       },
//       'Power Systems': {
//         roles: {
//           'Power Systems Engineer': {
//             Germany: {
//               demandLevel: 'High',
//               averageSalary: '€58,000 - €78,000',
//               visaProcess: 'EU Blue Card eligible',
//               keyRequirements: [
//                 'Grid integration',
//                 'Power distribution',
//                 'Energy storage systems',
//               ],
//               cities: ['Munich', 'Frankfurt', 'Berlin'],
//             },
//           },
//         },
//       },
//     },
//   },

//   'Construction & Infra': {
//     iconName: 'Building2',
//     subIndustries: {
//       'Civil Engineering': {
//         roles: {
//           'Structural Engineer': {
//             Germany: {
//               demandLevel: 'High',
//               averageSalary: '€50,000 - €70,000',
//               visaProcess: 'EU Blue Card eligible',
//               keyRequirements: [
//                 'Structural analysis',
//                 'AutoCAD',
//                 'Building codes',
//                 'Project management',
//               ],
//               cities: ['Berlin', 'Munich', 'Frankfurt'],
//             },
//           },
//         },
//       },
//     },
//   },

//   'Logistics & Supply Chain': {
//     iconName: 'Truck',
//     subIndustries: {
//       'Supply Chain Management': {
//         roles: {
//           'Supply Chain Manager': {
//             Netherlands: {
//               demandLevel: 'High',
//               averageSalary: '€55,000 - €75,000',
//               visaProcess: 'Knowledge migrant visa',
//               keyRequirements: [
//                 'Supply chain optimization',
//                 'ERP systems',
//                 'Inventory management',
//                 'Logistics planning',
//               ],
//               cities: ['Rotterdam', 'Amsterdam', 'Eindhoven'],
//             },
//           },
//         },
//       },
//     },
//   },
// };

// export default marketData;
