// import React, { useState, useEffect } from 'react';
// import videoFile from '../assets/videoHero.mp4';

// const mathFormulas = [
//   'E = mc²',
//   'f(x) = ax² + bx + c',
//   '∫ dx',
//   'π = 3.14159',
//   'x = -b ± √(b²-4ac)/2a',
//   'P(A|B)',
//   'Σ x = n(n+1)/2',
//   '∂y/∂x',
//   'e = 2.71828',
//   'y = mx + b',
//   'A ∩ B',
//   '∆x → 0',
// ];

// const Hero = () => {
//   const [formulas, setFormulas] = useState([]);

//   useEffect(() => {
//     const container = document.querySelector('section');
//     const bounds = container?.getBoundingClientRect();
//     const containerWidth = bounds?.width || window.innerWidth;
//     const containerHeight = bounds?.height || window.innerHeight;

//     const initialFormulas = Array.from({ length: 8 }, (_, i) => ({
//       id: i,
//       text: mathFormulas[Math.floor(Math.random() * mathFormulas.length)],
//       x: Math.random() * (containerWidth - 100),
//       y: Math.random() * (containerHeight - 30),
//       vx: (Math.random() - 0.5) * 2,
//       vy: (Math.random() - 0.5) * 2,
//       size: 0.8 + Math.random() * 0.4,
//       opacity: 0.2 + Math.random() * 0.3,
//     }));

//     setFormulas(initialFormulas);

//     const moveFormulas = () => {
//       setFormulas((prevFormulas) =>
//         prevFormulas.map((formula) => {
//           let newX = formula.x + formula.vx;
//           let newY = formula.y + formula.vy;
//           let newVx = formula.vx;
//           let newVy = formula.vy;

//           const currentBounds = container?.getBoundingClientRect();
//           const maxX = (currentBounds?.width || containerWidth) - 100;
//           const maxY = (currentBounds?.height || containerHeight) - 30;

//           if (newX < 20 || newX > maxX) {
//             newVx = -formula.vx * 0.8;
//             newX = newX < 20 ? 20 : maxX;
//           }
//           if (newY < 20 || newY > maxY) {
//             newVy = -formula.vy * 0.8;
//             newY = newY < 20 ? 20 : maxY;
//           }

//           return {
//             ...formula,
//             x: newX,
//             y: newY,
//             vx: newVx,
//             vy: newVy,
//           };
//         })
//       );
//     };

//     let animationFrameId;
//     let lastTime = 0;
//     const fps = 30;
//     const fpsInterval = 1000 / fps;

//     const animate = (currentTime) => {
//       animationFrameId = requestAnimationFrame(animate);

//       const elapsed = currentTime - lastTime;

//       if (elapsed > fpsInterval) {
//         lastTime = currentTime - (elapsed % fpsInterval);
//         moveFormulas();
//       }
//     };

//     animationFrameId = requestAnimationFrame(animate);

//     return () => {
//       if (animationFrameId) {
//         cancelAnimationFrame(animationFrameId);
//       }
//     };
//   }, []);

//   return (
//     <section className="relative w-auto min-h-[85vh] mobile:min-h-[70vh] bg-white mx-2 mobile:mx-3 sm:mx-4 md:mx-10 mt-2 mobile:mt-3 overflow-hidden rounded-2xl mobile:rounded-3xl">
//       {/* Math Formulas Background */}
//       <div className="absolute inset-0 overflow-hidden">
//         <div className="relative w-full h-full">
//           {formulas.map((formula) => (
//             <div
//               key={formula.id}
//               className="absolute font-serif transition-transform duration-75"
//               style={{
//                 left: formula.x,
//                 top: formula.y,
//                 transform: `scale(${formula.size})`,
//                 opacity: formula.opacity,
//                 color: '#4B5563',
//                 minWidth: 'fit-content',
//               }}
//             >
//               {formula.text}
//             </div>
//           ))}
//         </div>
//       </div>

//       <div className="grid lg:grid-cols-2 min-h-[85vh] mobile:min-h-[70vh]">
//         {/* Content Side */}
//         <div className="relative z-20 p-4 mobile:p-2 md:p-8 lg:p-8 flex items-center">
//           <div className="relative max-w-xl mx-auto lg:mx-0">
//             <div className="absolute inset-0 bg-white/0 lg:bg-white/30 backdrop-blur-sm rounded-xl" />
//             <div className="relative">
//               <h1 className="text-2xl mobile:text-4xl sm:text-4xl lg:text-5xl font-bold mb-3 mobile:mb-4 sm:mb-6 text-gray-900">
//                 Future-Proof Your Career
//               </h1>
//               <p className="text-sm mobile:text-lg sm:text-lg lg:text-2xl mb-4 mobile:mb-6 sm:mb-8 text-gray-600">
//                 Join our platform to access global job opportunities and
//                 industry-leading upskilling programs in AI-impacted sectors.
//               </p>

//               <div className="flex flex-col sm:flex-row gap-2 mobile:gap-3 sm:gap-4">
//                 <a
//                   href="https://forms.gle/Nb2oqFQc4xq2CCjN8"
//                   className="bg-teal-500 text-white font-semibold py-2 mobile:py-2.5 sm:py-3 px-4 mobile:px-6 sm:px-8 rounded-lg hover:bg-teal-600 transition duration-300 text-center text-xs mobile:text-sm sm:text-base"
//                 >
//                   Explore Opportunities
//                 </a>
//               </div>

//               <p className="mt-3 mobile:mt-4 sm:mt-6 text-[10px] mobile:text-xs sm:text-sm text-gray-500">
//                 Partnering with leading companies and educational institutions
//                 to prepare talent for the AI era
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* Video Side - Desktop */}
//         <div className="relative lg:block hidden">
//           <div className="absolute inset-0 bg-gradient-to-r from-white via-transparent to-transparent z-10" />
//           <video
//             className="h-full w-full object-cover bg-white"
//             src={videoFile}
//             autoPlay
//             loop
//             muted
//             playsInline
//             preload="auto"
//           />
//           <div className="absolute inset-0 bg-black opacity-30" />
//         </div>

//         {/* Video Background - Mobile/Tablet */}
//         <div className="lg:hidden absolute inset-0 -z-10">
//           <div className="absolute inset-0 bg-gradient-to-b from-white via-white/80 to-white/50 z-10" />
//           <video
//             className="h-full w-full object-cover bg-white"
//             src={videoFile}
//             autoPlay
//             loop
//             muted
//             playsInline
//           />
//           <div className="absolute inset-0 bg-black opacity-20" />
//         </div>
//       </div>

//       {/* Stats Section */}
//       <div className="absolute bottom-3 mobile:bottom-4 sm:bottom-8 left-3 mobile:left-4 sm:left-8 right-3 mobile:right-4 sm:right-8 z-30">
//         <div className="bg-white/30 lg:bg-white/80 backdrop-blur-sm rounded-lg mobile:rounded-xl p-3 mobile:p-4 sm:p-6 shadow-lg">
//           <div className="grid grid-cols-3 gap-2 mobile:gap-3 sm:gap-8">
//             <div className="text-center">
//               <div className="font-bold text-base mobile:text-lg sm:text-2xl text-teal-600">
//                 500+
//               </div>
//               <div className="text-[10px] mobile:text-xs sm:text-sm text-gray-600">
//                 Global Opportunities
//               </div>
//             </div>
//             <div className="text-center">
//               <div className="font-bold text-base mobile:text-lg sm:text-2xl text-teal-600">
//                 10+
//               </div>
//               <div className="text-[10px] mobile:text-xs sm:text-sm text-gray-600">
//                 Industry Partners
//               </div>
//             </div>
//             <div className="text-center">
//               <div className="font-bold text-base mobile:text-lg sm:text-2xl text-teal-600">
//                 85%
//               </div>
//               <div className="text-[10px] mobile:text-xs sm:text-sm text-gray-600">
//                 Success Rate
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// // export default Hero;
// import React, { useState, useEffect } from 'react';
// import videoFile from '../assets/videoHero.mp4';

// const TechLogo = ({ name }) => {
//   // Simple monochromatic SVG logos with consistent style
//   const logos = {
//     react: (
//       <svg viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
//         <path d="M12 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z" />
//         <path d="M12 21.35c-.96 0-1.88-.04-2.75-.12-2.37-.22-4.42-.89-5.97-1.92-1.53-1.02-2.37-2.27-2.37-3.54 0-1.28.84-2.53 2.37-3.55 1.55-1.03 3.6-1.7 5.97-1.92.87-.08 1.79-.12 2.75-.12.96 0 1.88.04 2.75.12 2.37.22 4.42.89 5.97 1.92 1.53 1.02 2.37 2.27 2.37 3.54 0 1.28-.84 2.53-2.37 3.55-1.55 1.03-3.6 1.7-5.97 1.92-.87.08-1.79.12-2.75.12Z" />
//         <path d="M15.45 4.37c2.17 1.26 3.99 2.92 5.28 4.85 1.29 1.94 2.03 4.11 2.15 6.31.11 2.19-.38 4.42-1.41 6.43-.82 1.6-1.93 3.03-3.27 4.24" />
//         <path d="M8.55 4.37c-2.17 1.26-3.99 2.92-5.28 4.85-1.29 1.94-2.03 4.11-2.15 6.31-.11 2.19.38 4.42 1.41 6.43.82 1.6 1.93 3.03 3.27 4.24" />
//       </svg>
//     ),
//     node: (
//       <svg viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
//         <path d="M12 21.35c-.96 0-1.88-.04-2.75-.12-2.37-.22-4.42-.89-5.97-1.92-1.53-1.02-2.37-2.27-2.37-3.54 0-1.28.84-2.53 2.37-3.55 1.55-1.03 3.6-1.7 5.97-1.92.87-.08 1.79-.12 2.75-.12.96 0 1.88.04 2.75.12 2.37.22 4.42.89 5.97 1.92 1.53 1.02 2.37 2.27 2.37 3.54 0 1.28-.84 2.53-2.37 3.55-1.55 1.03-3.6 1.7-5.97 1.92-.87.08-1.79.12-2.75.12Z" />
//       </svg>
//     ),
//     python: (
//       <svg viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
//         <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
//       </svg>
//     ),
//     aws: (
//       <svg viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
//         <path d="M12 3L4 7v10l8 4 8-4V7l-8-4zm6 11.17l-6 3-6-3V7.83l6-3 6 3v6.34z" />
//       </svg>
//     ),
//     angular: (
//       <svg viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
//         <path d="M14.5 2h-5L2 19.5l2.75 2.5h14.5l2.75-2.5L14.5 2zm-2.5 2.5l7.5 15h-15l7.5-15z" />
//       </svg>
//     ),
//     vue: (
//       <svg viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
//         <path d="M12 14.55L8.55 9.6h2.35L12 11.45l1.1-1.85h2.35L12 14.55zm0 4.9l-7.7-13h3.85L12 11.3l3.85-4.85h3.85L12 19.45z" />
//       </svg>
//     ),
//   };

//   return (
//     <div className="w-12 h-12 text-gray-400">{logos[name.toLowerCase()]}</div>
//   );
// };

// const Hero = () => {
//   const [logos, setLogos] = useState([]);

//   useEffect(() => {
//     const container = document.querySelector('section');
//     const bounds = container?.getBoundingClientRect();
//     const containerWidth = bounds?.width || window.innerWidth;
//     const containerHeight = bounds?.height || window.innerHeight;

//     const logoNames = ['react', 'node', 'python', 'aws', 'angular', 'vue'];
//     const initialLogos = Array.from({ length: 12 }, (_, i) => ({
//       id: i,
//       name: logoNames[Math.floor(Math.random() * logoNames.length)],
//       x: Math.random() * (containerWidth - 50),
//       y: Math.random() * (containerHeight - 50),
//       vx: (Math.random() - 0.5) * 1, // Slower movement
//       vy: (Math.random() - 0.5) * 1,
//       scale: 0.8 + Math.random() * 0.4,
//       opacity: 0.15 + Math.random() * 0.2, // More subtle
//     }));

//     setLogos(initialLogos);

//     const moveLogos = () => {
//       setLogos((prevLogos) =>
//         prevLogos.map((logo) => {
//           let newX = logo.x + logo.vx;
//           let newY = logo.y + logo.vy;
//           let newVx = logo.vx;
//           let newVy = logo.vy;

//           const currentBounds = container?.getBoundingClientRect();
//           const maxX = (currentBounds?.width || containerWidth) - 50;
//           const maxY = (currentBounds?.height || containerHeight) - 50;

//           if (newX < 0 || newX > maxX) {
//             newVx = -logo.vx;
//             newX = newX < 0 ? 0 : maxX;
//           }
//           if (newY < 0 || newY > maxY) {
//             newVy = -logo.vy;
//             newY = newY < 0 ? 0 : maxY;
//           }

//           return { ...logo, x: newX, y: newY, vx: newVx, vy: newVy };
//         })
//       );
//     };

//     let animationFrameId;
//     let lastTime = 0;
//     const fps = 30;
//     const fpsInterval = 1000 / fps;

//     const animate = (currentTime) => {
//       animationFrameId = requestAnimationFrame(animate);
//       const elapsed = currentTime - lastTime;

//       if (elapsed > fpsInterval) {
//         lastTime = currentTime - (elapsed % fpsInterval);
//         moveLogos();
//       }
//     };

//     animationFrameId = requestAnimationFrame(animate);

//     return () => {
//       if (animationFrameId) {
//         cancelAnimationFrame(animationFrameId);
//       }
//     };
//   }, []);

//   return (
//     <section className="relative w-auto min-h-[85vh] mobile:min-h-[70vh] bg-white mx-2 mobile:mx-3 sm:mx-4 md:mx-10 mt-2 mobile:mt-3 overflow-hidden rounded-2xl mobile:rounded-3xl">
//       {/* Floating Logos Background */}
//       <div className="absolute inset-0 overflow-hidden">
//         <div className="relative w-full h-full">
//           {logos.map((logo) => (
//             <div
//               key={logo.id}
//               className="absolute transition-all duration-300 ease-in-out"
//               style={{
//                 left: logo.x,
//                 top: logo.y,
//                 transform: `scale(${logo.scale})`,
//                 opacity: logo.opacity,
//               }}
//             >
//               <TechLogo name={logo.name} />
//             </div>
//           ))}
//         </div>
//       </div>

//       <div className="grid lg:grid-cols-2 min-h-[85vh] mobile:min-h-[70vh]">
//         {/* Content Side */}
//         <div className="relative z-20 p-4 mobile:p-2 md:p-8 2xl:mx-20 lg:p-8 flex items-center">
//           <div className="relative max-w-xl mx-auto lg:mx-0">
//             <div className="absolute inset-0 bg-white/0 lg:bg-white/30 backdrop-blur-sm rounded-xl" />
//             <div className="relative">
//               <h1 className="text-4xl mobile:text-4xl sm:text-4xl lg:text-5xl font-bold mb-3 mobile:mb-4 sm:mb-6 text-gray-900">
//                 Build Your Tech Team
//               </h1>
//               <p className="text-sm mobile:text-lg sm:text-lg lg:text-2xl mb-4 mobile:mb-6 sm:mb-8 text-gray-600">
//                 Connect with India's top tech talent and scale your team with
//                 easy compliance and seamless integration.
//               </p>

//               <div className="flex flex-col sm:flex-row gap-2 mobile:gap-3 sm:gap-4">
//                 <button className="bg-teal-500 text-white font-semibold py-2 mobile:py-2.5 sm:py-3 px-4 mobile:px-6 sm:px-8 rounded-lg hover:bg-teal-600 transition duration-300 text-center text-xs mobile:text-sm sm:text-base">
//                   Hire Tech Talent
//                 </button>
//                 <button className="border border-teal-500 text-teal-500 font-semibold py-2 mobile:py-2.5 sm:py-3 px-4 mobile:px-6 sm:px-8 rounded-lg hover:bg-teal-50 transition duration-300 text-center text-xs mobile:text-sm sm:text-base">
//                   Learn More
//                 </button>
//               </div>

//               <p className="mt-3 mobile:mt-4 sm:mt-6 text-[10px] mobile:text-xs sm:text-sm text-gray-500">
//                 End-to-end recruitment solutions with complete compliance
//                 management
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* Video Side - Desktop */}
//         <div className="relative lg:block hidden">
//           <div className="absolute inset-0 bg-gradient-to-r from-white via-transparent to-transparent z-10" />
//           <video
//             className="h-full w-full object-cover bg-white"
//             src={videoFile}
//             autoPlay
//             loop
//             muted
//             playsInline
//             preload="auto"
//           />
//           <div className="absolute inset-0 bg-black opacity-20" />
//         </div>

//         {/* Video Background - Mobile/Tablet */}
//         <div className="lg:hidden absolute inset-0 -z-10">
//           <div className="absolute inset-0 bg-gradient-to-b from-white via-white/80 to-white/50 z-10" />
//           <video
//             className="h-full w-full object-cover bg-white"
//             src={videoFile}
//             autoPlay
//             loop
//             muted
//             playsInline
//           />
//           <div className="absolute inset-0 bg-black opacity-20" />
//         </div>
//       </div>

//       {/* Stats Section */}
//       <div className="absolute bottom-3 mobile:bottom-4 sm:bottom-8 left-3 mobile:left-4 sm:left-8 right-3 mobile:right-4 sm:right-8 z-30">
//         <div className="bg-white/30 lg:bg-white/80 backdrop-blur-sm rounded-lg mobile:rounded-xl p-3 mobile:p-4 sm:p-6 shadow-lg">
//           <div className="grid grid-cols-3 gap-2 mobile:gap-3 sm:gap-8">
//             <div className="text-center">
//               <div className="font-bold text-base mobile:text-lg sm:text-2xl text-teal-600">
//                 24/7
//               </div>
//               <div className="text-[10px] mobile:text-xs sm:text-sm text-gray-600">
//                 Support
//               </div>
//             </div>
//             <div className="text-center">
//               <div className="font-bold text-base mobile:text-lg sm:text-2xl text-teal-600">
//                 100%
//               </div>
//               <div className="text-[10px] mobile:text-xs sm:text-sm text-gray-600">
//                 Compliance
//               </div>
//             </div>
//             <div className="text-center">
//               <div className="font-bold text-base mobile:text-lg sm:text-2xl text-teal-600">
//                 EU
//               </div>
//               <div className="text-[10px] mobile:text-xs sm:text-sm text-gray-600">
//                 Coverage
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Hero;

import React, { useEffect, useRef } from 'react';
import { ArrowRight, Users, Shield, Globe } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// ParticleCanvas component remains unchanged
const ParticleCanvas = () => {
  const canvasRef = useRef(null);
  const mouseRef = useRef({ x: 0, y: 0 });
  const isMobileRef = useRef(window.innerWidth <= 768);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let particles = [];

    class Particle {
      constructor() {
        this.reset();
      }

      reset() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.vx = (Math.random() - 0.5) * (isMobileRef.current ? 0.05 : 0.2);
        this.vy = (Math.random() - 0.5) * (isMobileRef.current ? 0.05 : 0.2);
        this.radius = Math.random() * (isMobileRef.current ? 0.8 : 1.5);
        this.life = 1;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(20, 184, 166, ${0.4 * this.life})`;
        ctx.fill();
      }

      update() {
        const dx = mouseRef.current.x - this.x;
        const dy = mouseRef.current.y - this.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const maxDistance = isMobileRef.current ? 70 : 150;

        // Enhanced mouse interaction
        if (distance < maxDistance) {
          const force =
            ((maxDistance - distance) / maxDistance) *
            (isMobileRef.current ? 0.3 : 1);
          this.x -= (dx / distance) * force;
          this.y -= (dy / distance) * force;
          this.life = Math.min(1, this.life + 0.05); // Particles become more visible near mouse
        } else {
          this.life = Math.max(0.3, this.life - 0.01); // Fade out when away from mouse
        }

        this.x += this.vx;
        this.y += this.vy;

        // Wrap around screen edges smoothly
        if (this.x < -50) this.x = canvas.width + 50;
        if (this.x > canvas.width + 50) this.x = -50;
        if (this.y < -50) this.y = canvas.height + 50;
        if (this.y > canvas.height + 50) this.y = -50;
      }
    }

    const handleResize = () => {
      const dpr = window.devicePixelRatio || 1;
      canvas.width = window.innerWidth * dpr;
      canvas.height = window.innerHeight * dpr;
      canvas.style.width = `${window.innerWidth}px`;
      canvas.style.height = `${window.innerHeight}px`;
      ctx.scale(dpr, dpr);
      isMobileRef.current = window.innerWidth <= 768;
      initParticles();
    };

    const initParticles = () => {
      const density =
        (canvas.width * canvas.height) / (isMobileRef.current ? 20000 : 8000);
      const count = Math.min(
        Math.max(density, isMobileRef.current ? 30 : 120),
        isMobileRef.current ? 60 : 200
      );
      particles = Array.from({ length: count }, () => new Particle());
    };

    const animate = () => {
      ctx.fillStyle = 'rgba(255, 255, 255, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      particles.forEach((particle) => {
        particle.update();
        particle.draw();
      });

      // Draw connections between particles
      const maxDist = isMobileRef.current ? 70 : 150;
      particles.forEach((p1, i) => {
        particles.slice(i + 1).forEach((p2) => {
          const dx = p1.x - p2.x;
          const dy = p1.y - p2.y;
          const dist = Math.sqrt(dx * dx + dy * dy);

          if (dist < maxDist) {
            const opacity =
              0.5 * (1 - dist / maxDist) * Math.min(p1.life, p2.life);
            ctx.beginPath();
            ctx.moveTo(p1.x, p1.y);
            ctx.lineTo(p2.x, p2.y);
            ctx.strokeStyle = `rgba(20, 184, 166, ${opacity})`;
            ctx.lineWidth = isMobileRef.current ? 0.2 : 0.5;
            ctx.stroke();
          }
        });
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    const handlePointerMove = (event) => {
      const touch = event.touches ? event.touches[0] : event;
      mouseRef.current = { x: touch.clientX, y: touch.clientY };
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handlePointerMove);
    window.addEventListener('touchmove', handlePointerMove, { passive: false });
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handlePointerMove);
      window.removeEventListener('touchmove', handlePointerMove);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas ref={canvasRef} className="w-full h-full" />;
};

// Updated Hero component with centered content
const Hero = () => {
  const navigate = useNavigate();
  return (
    <section className="relative w-full h-screen bg-white overflow-hidden">
      <div className="absolute inset-0">
        <ParticleCanvas />
      </div>

      <div className="relative z-20 flex items-center justify-center w-full h-full">
        <div className="container mx-auto px-4 md:px-6">
          <div className="flex justify-center items-center">
            <div className="text-center max-w-3xl mx-auto space-y-8">
              <div className="flex justify-center animate-fade-in-up">
                <span className="bg-teal-100 text-teal-800 text-sm font-medium px-6 py-2 rounded-full">
                  Now Hiring Globally
                </span>
              </div>

              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 leading-tight animate-fade-in-up px-4">
                Build High-Performance Tech Teams
                <span className="block text-teal-600 mt-2">
                  Anywhere, Anytime
                </span>
              </h1>

              <p className="text-lg text-gray-600 mx-auto px-4 animate-fade-in-up">
                Hire top-tier global talent in days, not months. TalentFirst
                helps you source, vet, and onboard world-class developers,
                engineers, and cybersecurity professionals—seamlessly and
                compliantly.
              </p>

              <div className="flex flex-col sm:flex-row justify-center gap-4 animate-fade-in-up px-4">
                <button
                  className="flex items-center justify-center gap-2 bg-teal-600 text-white px-8 py-4 rounded-lg hover:bg-teal-700 transition-all duration-300 hover:scale-105"
                  onClick={() => {
                    window.location.href = 'https://talent.talentfirst.global';
                  }}
                >
                  Join Talent Pool
                  <ArrowRight className="w-5 h-5" />
                </button>
                <button
                  onClick={() => {
                    navigate('/employer-hiring-form');
                  }}
                  className="flex items-center justify-center gap-2 border-2 border-teal-600 text-teal-600 px-8 py-4 rounded-lg hover:bg-teal-50 transition-all duration-300 hover:scale-105"
                >
                  Start Hiring Talent
                </button>
              </div>

              <div className="pt-8 mt-8 border-t border-gray-200 animate-fade-in-up px-4">
                <div className="flex flex-row justify-center flex-wrap gap-4 sm:gap-8 md:gap-12 max-w-4xl mx-auto">
                  <div className="flex items-center gap-2 group">
                    <Users className="w-6 h-6 sm:w-7 sm:h-7 text-teal-600 transition-transform group-hover:scale-110" />
                    <div>
                      <div className="font-semibold text-sm sm:text-base">
                        Talent Pool
                      </div>
                      <div className="text-xs sm:text-sm text-gray-500">
                        Skilled Developers
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 group">
                    <Shield className="w-6 h-6 sm:w-7 sm:h-7 text-teal-600 transition-transform group-hover:scale-110" />
                    <div>
                      <div className="font-semibold text-sm sm:text-base">
                        Secure
                      </div>
                      <div className="text-xs sm:text-sm text-gray-500">
                        Hiring Process
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 group">
                    <Globe className="w-6 h-6 sm:w-7 sm:h-7 text-teal-600 transition-transform group-hover:scale-110" />
                    <div>
                      <div className="font-semibold text-sm sm:text-base">
                        Worldwide
                      </div>
                      <div className="text-xs sm:text-sm text-gray-500">
                        Recruitment
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
