import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserCircle2, Building2, Menu, X } from 'lucide-react';
import logo from '../assets/logo-highdef.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleEmployerLogin = () => {
    navigate('/employer-hiring-form');
  };

  return (
    <header className="w-full bg-white">
      <div className="max-w-7xl mx-auto h-20 px-8">
        <div className="flex h-full items-center justify-between">
          {/* Logo */}
          <Link to="/" className="block">
            <img src={logo} alt="TalentFirst Logo" className="h-10 w-auto" />
          </Link>

          {/* Center Navigation */}
          <div className="hidden md:flex space-x-12">
            <Link to="/talent" className="text-gray-600 hover:text-gray-900">
              Talent
            </Link>
            <Link to="/employer" className="text-gray-600 hover:text-gray-900">
              Employer
            </Link>
            <Link to="/contact" className="text-gray-600 hover:text-gray-900">
              Contact
            </Link>
          </div>

          {/* Right: Login Buttons */}
          <div className="flex items-center space-x-4">
            <div className="hidden md:flex items-center space-x-4">
              <button
                onClick={handleEmployerLogin}
                className="inline-flex items-center justify-center rounded-full px-6 py-2.5 bg-teal-700 text-white hover:bg-gray-700"
              >
                <span>Start Hiring</span>
              </button>
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="md:hidden relative z-50"
            >
              {menuOpen ? (
                <X className="w-6 h-6 text-gray-900" />
              ) : (
                <Menu className="w-6 h-6 text-gray-900" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="md:hidden fixed inset-0 bg-white z-40 pt-20">
          <div className="flex flex-col items-center space-y-8 p-8">
            <Link
              to="/"
              className="text-lg text-gray-600 hover:text-gray-900"
              onClick={() => setMenuOpen(false)}
            >
              Home
            </Link>
            <Link
              to="/talent"
              className="text-lg text-gray-600 hover:text-gray-900"
              onClick={() => setMenuOpen(false)}
            >
              Talent
            </Link>
            <Link
              to="/employer"
              className="text-lg text-gray-600 hover:text-gray-900"
              onClick={() => setMenuOpen(false)}
            >
              Employer
            </Link>
            <Link
              to="/contact"
              className="text-lg text-gray-600 hover:text-gray-900"
              onClick={() => setMenuOpen(false)}
            >
              Contact
            </Link>
            <div className="flex flex-col space-y-4 w-full px-4 pt-4">
              <button
                onClick={() => {
                  window.location.href = 'https://talent.talentfirst.global';
                }}
                className="inline-flex items-center justify-center rounded-full px-6 py-2.5 text-gray-700 hover:text-gray-900 hover:bg-gray-100"
              >
                <UserCircle2 className="w-4 h-4 mr-2" />
                <span>Talent</span>
              </button>
              <button
                onClick={() => {
                  navigate('/employer-hiring-form');
                  setMenuOpen(false);
                }}
                className="inline-flex items-center justify-center rounded-full px-6 py-2.5 bg-blue-950 text-white hover:bg-gray-700"
              >
                <Building2 className="w-4 h-4 mr-2" />
                <span>Employer</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
