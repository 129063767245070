// import React from 'react';
// import { ArrowRight } from 'lucide-react';

// const CallToAction = () => {
//   return (
//     <div className="w-full bg-gradient-to-b from-white to-teal-50/20">
//       <div className="max-w-6xl mx-auto px-4 py-16">
//         <div className="relative">
//           {/* Decorative elements */}
//           <div className="absolute left-0 top-0 w-24 h-24 bg-teal-100/50 rounded-full -translate-x-1/2 -translate-y-1/2" />
//           <div className="absolute right-0 bottom-0 w-32 h-32 bg-teal-100/50 rounded-full translate-x-1/4 translate-y-1/4" />

//           {/* Main content */}
//           <div className="relative bg-gradient-to-br from-teal-500 to-teal-600 rounded-2xl p-12 shadow-xl overflow-hidden">
//             {/* Background decorative circle */}
//             <div className="absolute right-0 top-0 w-64 h-64 bg-teal-400/20 rounded-full translate-x-1/3 -translate-y-1/3" />

//             <div className="max-w-3xl mx-auto text-center space-y-6 relative z-10">
//               <div className="space-y-2">
//                 <h2 className="text-3xl font-bold text-slate-100 tracking-tight">
//                   Build Your Career Globally
//                 </h2>
//                 <h3 className="text-5xl font-extrabold text-white tracking-wider animate-pulse">
//                   TODAY!!!
//                 </h3>
//               </div>

//               <p className="text-xl text-teal-50 tracking-wide">
//                 Join TalentFirst to unlock opportunities in Europe
//               </p>

//               <div className="flex justify-center pt-6">
//                 <button
//                   onClick={() =>
//                     (window.location.href =
//                       'https://forms.gle/Nb2oqFQc4xq2CCjN8')
//                   }
//                   className="group px-8 py-4 bg-white text-teal-600 rounded-lg font-semibold
//                            hover:bg-teal-50 transition-all duration-300
//                            shadow-md hover:shadow-xl hover:-translate-y-0.5
//                            flex items-center gap-3"
//                 >
//                   Explore Path to Germany
//                   <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CallToAction;

// import React from 'react';
import {
  Phone,
  Calendar,
  Clock,
  ArrowRight,
  Users,
  Shield,
} from 'lucide-react';

const ConsultationCTA = () => {
  return (
    <div className="w-full bg-slate-50">
      <div className="max-w-6xl mx-auto px-4 py-16">
        <div className="relative">
          {/* Decorative elements */}
          <div className="absolute left-0 top-0 w-24 h-24 bg-blue-100/60 rounded-full -translate-x-1/2 -translate-y-1/2" />
          <div className="absolute right-0 bottom-0 w-32 h-32 bg-blue-100/60 rounded-full translate-x-1/4 translate-y-1/4" />

          {/* Main content */}
          <div className="relative bg-white rounded-2xl p-12 shadow-xl overflow-hidden">
            {/* Background decorative circle */}
            <div className="absolute right-0 top-0 w-64 h-64 bg-blue-50/50 rounded-full translate-x-1/3 -translate-y-1/3" />

            <div className="relative z-10">
              {/* Top section with icon grid */}
              <div className="grid grid-cols-3 gap-4 mb-8 max-w-3xl mx-auto">
                {[
                  { icon: <Clock className="w-6 h-6" />, text: '15-min call' },
                  {
                    icon: <Users className="w-6 h-6" />,
                    text: 'Expert consultation',
                  },
                  {
                    icon: <Shield className="w-6 h-6" />,
                    text: 'No obligation',
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center p-4 rounded-xl bg-blue-50 text-blue-600"
                  >
                    {item.icon}
                    <span className="text-xs mt-2 text-center">
                      {item.text}
                    </span>
                  </div>
                ))}
              </div>

              {/* Main call-to-action content */}
              <div className="text-center mb-8 max-w-3xl mx-auto">
                <h3 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
                  Schedule Your Free Consultation Call
                </h3>
                <p className="text-xl text-gray-600 tracking-wide">
                  Discover how we can help build your dream tech team. Our
                  experts will understand your needs and provide tailored
                  solutions.
                </p>
              </div>

              {/* Booking options */}
              <div className="flex flex-col sm:flex-row justify-center gap-4 max-w-xl mx-auto">
                <button
                  onClick={() => (window.location.href = 'tel:+917975563057')}
                  className="flex items-center justify-center gap-3 px-8 py-4 bg-blue-600 text-white rounded-lg font-semibold
                           hover:bg-blue-700 transition-all duration-300
                           shadow-md hover:shadow-xl hover:-translate-y-0.5 group"
                >
                  <Phone className="w-5 h-5" />
                  <span>Call Now</span>
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
                </button>

                <button
                  onClick={() =>
                    (window.location.href =
                      'https://calendly.com/bryan-talentfirst/helping-you-find-the-right-talent-for-your-team')
                  }
                  className="flex items-center justify-center gap-3 px-8 py-4 bg-white border-2 border-blue-200 text-blue-600 rounded-lg font-semibold
                           hover:border-blue-600 hover:text-blue-600 transition-all duration-300
                           shadow-md hover:shadow-xl hover:-translate-y-0.5 group"
                >
                  <Calendar className="w-5 h-5" />
                  <span>Schedule Online</span>
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
                </button>
              </div>

              {/* Trust indicators */}
              <div className="mt-8 pt-6 border-t border-gray-100 max-w-3xl mx-auto">
                <div className="flex justify-center items-center gap-2 text-sm text-gray-500">
                  <span>Join 200+ satisfied clients</span>
                  <span className="w-1 h-1 rounded-full bg-blue-200"></span>
                  <span>4.9/5 satisfaction rate</span>
                  <span className="w-1 h-1 rounded-full bg-blue-200"></span>
                  <span>Expert consultation</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultationCTA;
