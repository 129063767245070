import React from 'react';
import { Users, CheckCircle, Building, Code2, Sparkles } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const TechProgramHighlight = () => {
  const navigate = useNavigate();
  const services = [
    {
      icon: Code2,
      title: 'Hire Tech Talent',
      description:
        'Access pre-vetted Indian developers for your European tech team',
      features: ['Senior developers', 'Full-stack teams', 'Specialized roles'],
      color: 'bg-blue-50 text-blue-600',
      border: 'border-blue-100',
    },
    {
      icon: Building,
      title: 'Easy Compliance',
      description: 'Complete employment and regulatory compliance management',
      features: [
        'Contract handling',
        'Payroll management',
        'Legal documentation',
      ],
      color: 'bg-emerald-50 text-emerald-600',
      border: 'border-emerald-100',
    },
    {
      icon: Users,
      title: 'Team Integration',
      description: 'Seamless onboarding and team collaboration setup',
      features: [
        'Remote workspace',
        'Communication tools',
        'Integration support',
      ],
      color: 'bg-purple-50 text-purple-600',
      border: 'border-purple-100',
    },
  ];

  return (
    <section className="py-12 sm:py-14">
      {/* Main Container */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* Header Section */}
        <div className="text-center mb-12 sm:mb-20">
          <div className="inline-flex items-center space-x-2 bg-blue-50 px-3 py-1 rounded-full mb-6">
            <Sparkles className="w-4 h-4 text-blue-600" />
            <span className="text-sm font-medium text-blue-600">
              Tech Talent Solutions
            </span>
          </div>

          <h2 className="text-4xl sm:text-5xl font-semibold text-gray-900 mb-6 sm:mb-8">
            Build Your Dream Team<br></br>
            <span className="text-blue-600">with TalentFirst</span>
          </h2>

          <p className="text-base sm:text-lg text-gray-600 max-w-2xl mx-auto">
            Access top tech talent with complete compliance and integration
            support
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-3 gap-6 sm:gap-8 mb-16">
          {services.map((service, index) => (
            <div
              key={index}
              className="group bg-white rounded-2xl p-6 sm:p-8 border border-gray-200 hover:border-transparent hover:shadow-xl transition-all duration-300 flex flex-col items-center text-center"
            >
              <div
                className={`w-12 h-12 ${service.color} rounded-lg flex items-center justify-center mb-6`}
              >
                <service.icon className="w-6 h-6" />
              </div>

              <h3 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-4 group-hover:text-blue-600 transition-colors">
                {service.title}
              </h3>

              <p className="text-gray-600 mb-6 text-sm sm:text-base">
                {service.description}
              </p>

              <ul className="space-y-3 w-full">
                {service.features.map((feature, idx) => (
                  <li
                    key={idx}
                    className="flex items-center justify-center space-x-3"
                  >
                    <CheckCircle className="w-5 h-5 text-blue-600 flex-shrink-0" />
                    <span className="text-gray-600 text-sm sm:text-base">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Additional Info Section */}

        {/* CTA Section */}
        <div className="mt-16 text-center">
          <button
            className="inline-flex items-center justify-center bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors"
            onClick={() => {
              navigate('/employer-hiring-form');
            }}
          >
            Start Building Your Team
          </button>
          <p className="mt-4 text-sm text-gray-600">
            Fast, reliable, and compliant tech hiring solutions
          </p>
        </div>
      </div>
    </section>
  );
};

export default TechProgramHighlight;
